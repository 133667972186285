function server(path) {
    if (path.startsWith("/")) path = path.substring(1);
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return `http://localhost:5008/${path}`;
    }
    return `${window.location.origin}/${path}`;
}

const defaultConfig = {
    server: server,
    graphQL: server("graphql"),

    accentColor: "#ef5a35",
    storageImageFolder: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
        ? `https://moravskimarket.rs/storage/market/slike`
        : server("storage/market/slike"),
    storageFileFolder: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
        ? `https://moravskimarket.rs/storage/market/fajlovi`
        : server("storage/market/fajlovi"),

    languages: [
        {id: 0, short: 'sr', value: "/", name: "Srpski", label: "RS"},
        //{id: 1, short: 'en', value: "/home", name: "English", label: "EN"}
    ],
    cmsEntryPoint: "products.CmsProducts",

    campaignVar: "cmpgn",

    pagesMenus: [
        {name: "header meni", value: 1},
        {name: "FAQ meni", value: 2},
        {name: "sakriveni", value: 3}
    ],
    pagesLvl: 1,

    productsColumns: 4,
    productsOrders: [
        {title: "po relevantnosti", value: ""},
        {title: "po pregledima", value: 8, admin: true},
        {title: "dodato u listu zelja", value: 9, admin: true},
        {title: "po nazivu", value: 2},
        {title: "po ceni rastuce", value: 3},
        {title: "po ceni opadajuce", value: 4},
    ],

    orderMaxQty: 10,

    orderStatuses: {
        1: {title: "u obradi", color: "#ff9e00", value: 1},
        2: {title: "primljeno", color: "#006fca", value: 2},
        3: {title: "poslato", color: "#009a56", value: 3},
        4: {title: "isporuceno", color: "#009a56", value: 4},
        5: {title: "otkazano", color: "#d00000", value: 5},
        6: {title: "reklamacija", color: "#D400B5FF", value: 6},
    },

    carousel: {
        arrows: false,
        dots: true
    },

    banners: {
        "shopmenu": {
            name: "shop strana",
            description: "shop strana desc",
        },
        "productright": {
            name: {sr: "Proizvod strana", en: "Product page"},
            description: {sr: "Desni baneri", en: "Banners on the right"}
        },
    },

    modules: ["banners","blog","brands","categories","cms","design","groups",
        "lang","pages","products","public","pwa","seo","settings","storage",
        "textEditor","wishlist","files","orders","users","filters"],

    designTypes: {
        slider: {type: 1, name: "veliki slajder"},
        banners: {type: 2, name: "baneri"},
        products: {type: 3, name: "carousel proizvoda"},
        categories: {type: 5, name: "kategorije u carousel-u"},
        editor: {type: 6, name: "TEXT editor"},
        brands: {type: 7, name: "brendovi u carousel-u"},
        blog: {type: 8, name: "BLOG carousel"},
        cards: {type: 9, name: "text i slika"},
    },

    product: {
        connected: true,
        gallery: true,
        specifications: true,
        youtube: true,
        price: true,
        filters: true,
        long_description: true
    },
    productStatuses: [
        {value: "action", name: "akcija"},
        {value: "new", name: "novo"},
        {value: "popular", name: "popularno"},
        {value: "posno", name: "posno"},
        {value: "prodavnica", name: "samo u prodavnici"},
    ],
}
export default defaultConfig;